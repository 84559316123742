<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="融资企业名称">
              <el-input v-model="searchForm.customerName" placeholder="请输入要搜索的融资企业名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资金方名称">
              <el-input v-model="searchForm.capName" placeholder="请输入要搜索的资金方名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="核心企业名称">
              <el-input v-model="searchForm.coreName" placeholder="请输入要搜索的核心企业名称" />
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :hide-column-btn="true" show-summary :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" :summary-method="getSummaries" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button v-loading="loading.export" type="primary" @click="exportFile">
            导出
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column label="融资企业名称" align="center" show-overflow-tooltip min-width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.customerName">
                {{ scope.row.customerName }}
              </span>
              <span v-else>
                {{ scope.row.customer_name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="核心企业名称" align="center" show-overflow-tooltip min-width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.coreName">
                {{ scope.row.coreName }}
              </span>
              <span v-else>
                -
              </span>
            </template>
          </el-table-column>
          <el-table-column label="资金方名称" align="center" show-overflow-tooltip min-width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.capName">
                {{ scope.row.capName }}
              </span>
              <span v-else>
                {{ scope.row.cap_name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="createdAt" label="创建时间" align="center" show-overflow-tooltip min-width="150" />
          <el-table-column prop="passAmount" label="授信总额（元）" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="useAmount" label="冻结授信（元）" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="availableAmount" label="可用授信（元）" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="send" label="借款金额（本金）" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        customerName: '',
        capName: '',
        coreName: '',
        afterEffective: '',
        beforeEffective: ''
      },
      sumInfo: {},
      columnOption: [
        { label: '融资企业名称', prop: 'coreCode', formatter: this.utils.isEffective, canSet: false, fixed: 'left', minWidth: 100 },
        { label: '核心企业名称', prop: 'coreName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '资金方名称', prop: 'coreSocialNo', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '授信总额(元)', prop: 'createdBy', formatter: this.utils.tableMoneyFormat, minWidth: 140 },
        { label: '冻结总额(元)', prop: 'createdBy', formatter: this.utils.tableMoneyFormat, minWidth: 140 },
        { label: '可用总额(元)', prop: 'createdBy', formatter: this.utils.tableMoneyFormat, minWidth: 140 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('afterEffective', 'beforeEffective')
  },
  created() {
    this.getQuotaPageSum()
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.quota.customer.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    getQuotaPageSum () {
      this.api.quota.customer.quotaPageSum().then(result => {
        this.sumInfo = result.data.data
        console.log(result)
      }).finally(() => {
      })
    },
    getSummaries (param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '总计';
          return;
        }
        let values = []
        if (index === 4) {
          values = [this.sumInfo.passAmount]
        }
        if (index === 5) {
          values = [this.sumInfo.useAmount]
        }
        if (index === 6) {
          values = [this.sumInfo.availableAmount]
        }
        if (index === 7) {
          values = [this.sumInfo.send]
        }
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      })
      sums.forEach((item, index) => {
        if (index >= 4) {
          sums[index] = this.utils.moneyFormat(item,2)
        }
      })
      return sums;
    },
    enableCore (coreCode, enabled) {
      this.api.company.core.updateEnable(coreCode, enabled).then(result => {
        this.$message.success(result.data.message || '操作成功')
        this.getList()
      }).finally(() => {
      })
    },
    exportFile () {
      this.loading.export = true
      this.api.quota.customer.exportFile(this.searchForm).then(result => this.utils.exportFile(result)).finally(() => {
        this.loading.export = false
      })
    },
  }
}
</script>

<style>

</style>
